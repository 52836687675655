import React, { useContext, useEffect, useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { LINKS } from './links';
import ThemeToggle from '../../../ThemeToggle';
import { ThemeContext } from '../../../ThemeContext';
import './Navigation.scss';

const Navigation = ({ account, setAccount }) => {
  const [isSticky, setIsSticky] = useState(false);

  const toggleNav = () => {
    const checkbox = document.getElementById('navi-toggle');
    checkbox.checked = !checkbox.checked;
  };

  const { theme } = useContext(ThemeContext);
  const logoURL =
    theme === 'light'
      ? 'https://ik.imagekit.io/pf0qkwdsv/Portfolio/VCSoft-logo-light_U73sfoj8x.svg?updatedAt=1730138295165'
      : 'https://ik.imagekit.io/pf0qkwdsv/Portfolio/VCSoft_logo_ekTEibM-L.svg?updatedAt=1730137008620';

  const menuItemColor = theme === 'dark' ? '#fff' : '#828282';

  useEffect(() => {
    const handleScroll = () => {
      const bannerHeight = document.getElementById('banner').offsetHeight;
      if (window.scrollY > bannerHeight) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <>
      <nav id="navbar" className={`${isSticky ? 'sticky' : ''} ${theme}`}>
        <div className="nav__brand" onClick={toggleNav}>
          <Link to="/">
            <img src={logoURL} alt="logo" />
          </Link>
        </div>
        <div className="nav__btn">
          <input
            type="checkbox"
            className="nav__btn__checkbox"
            id="navi-toggle"
          />
          <label htmlFor="navi-toggle" className="nav__btn__button">
            <span className="nav__btn__icon">&nbsp;</span>
          </label>
          <div className="nav__btn__background">&nbsp;</div>
          <div className={`nav__btn__nav ${theme}`}>
            <ul className="nav__btn__list">
              {LINKS.map((link) => (
                <li
                  key={link.url}
                  className={`nav__btn__item ${theme === 'dark' ? 'dark' : ''}`}
                  onClick={toggleNav}
                >
                  <NavLink
                    to={link.url}
                    className="nav__btn__link"
                    style={({ isActive }) => ({
                      color: isActive ? '#ff5403' : menuItemColor
                    })}
                  >
                    {link.name}
                  </NavLink>
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className="nav__links">
          <ul>
            {LINKS.map((link) => (
              <li
                key={link.url}
                className={`${theme === 'dark' ? 'dark' : 'light'}`}
              >
                <NavLink
                  to={link.url}
                  style={({ isActive }) => ({
                    color: isActive ? '#ff5403' : menuItemColor
                  })}
                >
                  {link.name}
                </NavLink>
              </li>
            ))}
            <li className="theme-toggle">
              {/* <ThemeToggle /> */}
            </li>
          </ul>
        </div>
      </nav>
    </>
  );
};

export default Navigation;
